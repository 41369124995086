import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

interface Props {
  title?: string;
  description?: string;
  prefix?: string;
  image?: string;
}

const SEO: React.FC<Props> = (props) => {
  const { title, description, prefix, image } = props;
  const router = useRouter();
  const formattedTitle = title
    ? title
    : `${prefix ? `${prefix} | ` : ''}Latency Lingo`;
  const twitterTitle = title
    ? title
    : 'Simple and collaborative performance testing analytics';
  const formattedImage = image
    ? `https://latencylingo.com/${image}`
    : 'https://latencylingo.com/img/logo_full_v2.png';

  const formattedDescription = description
    ? description
    : 'Latency Lingo helps software engineering teams gain insights on web performance testing metrics.';

  const formattedUrl = `https://latencylingo.com${router.asPath}`;

  const GoogleAnalytics = () => {
    if (
      typeof window === 'undefined' ||
      window.location.hostname == 'localhost'
    ) {
      return null;
    }

    const gtags = [
      process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
      process.env.NEXT_PUBLIC_FIREBASE_ADS_ID,
    ];

    return (
      <>
        {gtags.map((tag, i) => (
          <Script
            async
            key={i}
            src={`https://www.googletagmanager.com/gtag/js?id=${tag}`}
          />
        ))}
        <Script
          dangerouslySetInnerHTML={{
            __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  ${gtags.map((tag) => `gtag('config', '${tag}');`).join('\n')}
              `,
          }}
        />
      </>
    );
  };

  const ClearBitAnalytics = () => {
    if (
      typeof window === 'undefined' ||
      window.location.hostname == 'localhost'
    ) {
      return null;
    }

    return (
      <Script
        async
        src="https://tag.clearbitscripts.com/v1/pk_0e056f1cd6a691121103023fd96c1f74/tags.js"
        referrerPolicy="strict-origin-when-cross-origin"
      />
    );
  };

  return (
    <>
      <GoogleAnalytics />
      <ClearBitAnalytics />
      <Head>
        <title>{formattedTitle}</title>
        <meta name="description" content={formattedDescription}></meta>
        <meta content={formattedImage} property="og:image"></meta>
        <meta content={formattedUrl} property="og:url"></meta>
        <meta content="website" property="og:type"></meta>
        <meta content={formattedTitle} property="og:title"></meta>
        <meta content={formattedDescription} property="og:description"></meta>
        <meta name="slack-app-id" content="A03KZAGKH8Q"></meta>

        {/* Twitter */}
        <meta name="twitter:title" content={twitterTitle}></meta>
        <meta name="twitter:description" content={formattedDescription}></meta>
        <meta name="twitter:image" content={formattedImage}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:image:alt" content="Latency Lingo"></meta>

        {/* Favicon */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#2b5797"></meta>
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
    </>
  );
};

export default SEO;
