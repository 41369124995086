import { ReactNode } from 'react';
import { NextPage } from 'next';

import Navbar from 'components/home/Navbar';
import Footer from 'components/home/Footer';
import NotificationBar from 'components/shared/NotificationBar';

interface Props {
  children: ReactNode;
}

const Layout: NextPage<Props> = ({ children }) => {
  return (
    <div className="min-h-screen h-1">
      <div className="bg-summer-green-100 font-medium text-summer-green-700 border border-summer-green-300 py-3">
        <NotificationBar>
          New release!{' '}
          <a
            target="_blank"
            className="underline"
            href="/blog/github-actions-integration"
            rel="noreferrer"
          >
            Github Actions Integration
          </a>
        </NotificationBar>
      </div>

      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
