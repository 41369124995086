import { Popover, Transition } from '@headlessui/react';
import Link from 'next/link';
import { useState } from 'react';
import { Fragment, useEffect, useRef } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { MdOutlineHealthAndSafety } from 'react-icons/md';
import { TbFileAnalytics, TbGitPullRequestClosed } from 'react-icons/tb';

const solutions = [
  {
    name: 'Automated Testing',
    description: 'Integrate with your CI/CD pipeline.',
    href: '/use-cases/automated-testing',
    icon: <TbGitPullRequestClosed className="w-5 h-5 sm:w-6 sm:h-6" />,
  },
  {
    name: 'Thresholds',
    description: 'Measure against objectives.',
    href: '/product/thresholds',
    icon: <MdOutlineHealthAndSafety className="w-5 h-5 sm:w-6 sm:h-6" />,
  },
  {
    name: 'Analysis',
    description: 'Explore load test results.',
    href: '/product/analysis',
    icon: <TbFileAnalytics className="w-5 h-5 sm:w-6 sm:h-6" />,
  },
];

interface WindowSize {
  width: number;
  height: number;
}

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};

const ProductMenuLink = () => {
  const buttonRef = useRef(null);
  const timeoutDuration = 200;
  let timeout;
  const { width } = useWindowSize();

  const closePopover = () => {
    return buttonRef.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      })
    );
  };

  const onMouseEnter = (open) => {
    clearTimeout(timeout);
    if (open || (width && width < 768)) return;
    return buttonRef.current?.click();
  };

  const onMouseLeave = (open) => {
    if (!open || (width && width < 768)) return;
    timeout = setTimeout(() => closePopover(), timeoutDuration);
  };

  return (
    <div>
      <Popover className="relative">
        {({ open }) => {
          return (
            <>
              <div onMouseLeave={onMouseLeave.bind(null, open)}>
                <Popover.Button
                  ref={buttonRef}
                  className={`${
                    open ? 'text-san-juan-blue-700' : 'text-san-juan-blue-500'
                  } mx-3 inline-flex items-center text-base focus:outline-none`}
                  onMouseEnter={onMouseEnter.bind(null, open)}
                  onMouseLeave={onMouseLeave.bind(null, open)}
                >
                  <span>Product</span>
                  <BiChevronDown
                    className={`${open ? '' : 'text-opacity-70'}
                    ml-1 h-5 w-5 text-summer-green-800 transition ease-in-out duration-150`}
                    aria-hidden="true"
                  />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 w-80 mt-0 transform -translate-x-16">
                    <div
                      className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                      onMouseEnter={onMouseEnter.bind(null, open)}
                      onMouseLeave={onMouseLeave.bind(null, open)}
                    >
                      <div className="relative grid gap-8 bg-white p-7">
                        {solutions.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50"
                          >
                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-summer-green-700 bg-summer-green-100 border border-summer-green-300 rounded-lg sm:h-12 sm:w-12">
                              {item.icon}
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium text-gray-900">
                                {item.name}
                              </p>
                              <p className="text-sm text-gray-500">
                                {item.description}
                              </p>
                            </div>
                          </a>
                        ))}
                      </div>
                      <div className="p-4 bg-gray-50">
                        <a
                          href="https://docs.latencylingo.com/docs/intro"
                          className="flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100"
                        >
                          <span className="flex items-center">
                            <span className="text-sm font-medium text-gray-900">
                              Documentation
                            </span>
                          </span>
                          <span className="block text-sm text-gray-500">
                            Start integrating Latency Lingo
                          </span>
                        </a>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </div>
            </>
          );
        }}
      </Popover>
    </div>
  );
};

const Navbar = (): JSX.Element => {
  return (
    <header className="m-auto text-gray-700 body-font max-w-7xl lg:max-w-full">
      <div className="container flex flex-col flex-wrap items-center p-5 mx-auto md:flex-row">
        <Link
          href="/"
          className="flex items-center mb-4 font-medium text-san-juan-blue-500 title-font md:mb-0"
        >
          <img
            className="w-auto h-8 sm:h-10"
            src="/img/logo.png"
            alt="Latency Lingo"
          />
          <span className="ml-3 text-summer-green-700">Latency Lingo</span>
        </Link>
        <nav className="flex flex-wrap items-center justify-center text-base md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400">
          <ProductMenuLink />
          <a
            href="https://docs.latencylingo.com/docs/intro"
            className="mx-3 text-base text-san-juan-blue-500 hover:text-san-juan-blue-700"
            rel="noreferrer"
          >
            Docs
          </a>
          <a
            href="/#pricing"
            className="mx-3 text-base text-san-juan-blue-500 hover:text-san-juan-blue-700"
          >
            Pricing
          </a>
        </nav>
        <div>
          <Link href="/login">
            <button className="px-4 py-2 mt-4 mr-4 text-sm transition font-medium duration-150 ease-in-out text-san-juan-blue-500 bg-summer-green-400 border-summer-green-500 border rounded-md hover:bg-summer-green-500 focus:outline-none focus:border-summer-green-600 md:mt-0">
              Sign In
            </button>
          </Link>
          <Link href="/signup">
            <button className="px-4 py-2 mt-4 text-sm text-summer-green-700 font-medium border-summer-green-300 transition duration-150 ease-in-out border border-transparent rounded-md bg-summer-green-100 hover:bg-summer-green-200 focus:outline-none focus:border-summer-green-700 md:mt-0">
              Sign Up
            </button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
