interface Props {
  learnMoreLink?: string;
}

const CtaSection: React.FC<Props> = ({ learnMoreLink }) => {
  return (
    <section className="text-gray-700 bg-gray-100" id="call-to-action">
      <div className="container mx-auto px-5 py-24">
        <div className="flex flex-col md:flex-row mx-auto w-full 2xl:w-4/6 justify-between">
          <div className="text-2xl font-bold text-san-juan-blue-500 title-font sm:text-5xl text-center sm:text-left">
            Ready to try it out? <br />
            <span className="text-summer-green-700">
              Publish reports today!
            </span>
          </div>
          <div className="flex justify-center mt-5 sm:mt-0">
            <a href="/signup">
              <button className="inline-flex px-8 py-4 text-lg font-medium text-san-juan-blue-500 bg-summer-green-400 border border-summer-green-500 hover:bg-summer-green-500 shadow rounded focus:outline-none">
                Try FREE now
              </button>
            </a>
            <a
              href={learnMoreLink || 'https://docs.latencylingo.com/docs/intro'}
              target="_blank"
              rel="noreferrer"
            >
              <button className="inline-flex ml-4 px-8 py-4 text-lg font-medium text-summer-green-700 bg-summer-green-100 shadow border border-summer-green-300 rounded focus:outline-none hover:bg-summer-green-200">
                Learn more
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
