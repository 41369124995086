interface Props {
  children: React.ReactNode;
}

const NotificationBar: React.FC<Props> = ({ children }) => {
  return (
    <div className="w-full h-full text-md py-2 text-center">
      <div className="inline-block max-w-4xl px-8 text-center">{children}</div>
    </div>
  );
};

export default NotificationBar;
